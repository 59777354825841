import React from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import { useTranslation } from '@wix/yoshi-flow-editor';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import ArrowBackIcon from '../../../common/components/icons/arrow-back-icon';
import BackButton from '../../containers/back-button';
import styles from './header-back-button.scss';

type Props = {
  component: React.ElementType;
  arrow: boolean;
  text: string;
  contentFontClassName?: string;
};

const HeaderBackButton = ({
  component: ContainerComponent = BackButton,
  arrow,
  text = 'header-back-button.back',
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { contentFontClassName } = useFontClassName();
  const iconClassName = classNames(styles.icon, 'blog-button-primary-icon-fill');
  const containerComponentProps =
    ContainerComponent === 'button'
      ? omit(rest, ['titleFontClassName', 'dispatch', 'tReady', 'lng'])
      : rest;

  const wrapperClassName = classNames(styles.wrapper, contentFontClassName);
  const labelClassName = classNames(styles.label, 'blog-button-primary-text-color');

  return (
    <ContainerComponent
      className={classNames(styles.button, 'header-back-button')}
      ariaLabel={t('header-back-button.back')}
      {...containerComponentProps}
    >
      <span className={wrapperClassName}>
        {arrow ? <ArrowBackIcon className={iconClassName} /> : null}
        <span className={labelClassName}>{text && t(text)}</span>
      </span>
    </ContainerComponent>
  );
};

export default HeaderBackButton;
